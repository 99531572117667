import React, { useState } from 'react';
import {
  Box,
  Button,
  Input,
  VStack,
  Text,
  useToast,
} from '@chakra-ui/react';

const AdminPage = () => {
  const [password, setPassword] = useState('');
  const [isSnapshot, setIsSnapshot] = useState(false); // Keeps track of the current mode
  const toast = useToast();

  const handleSnapshotAndToggle = async () => {
    // Take a snapshot first
    const snapshotResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/generateAndSaveLeaderboard`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ password }),
    });
  
    if (snapshotResponse.ok) {
      // If the snapshot was successful and we're currently in live mode, toggle to snapshot mode
      if (!isSnapshot) { // Only toggle if we're in live mode
        await handleToggle(true); // Force toggle to snapshot mode
        toast({
          title: 'Snapshot taken and mode toggled to Snapshot.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        // If we're already in snapshot mode, just inform the snapshot was successful
        toast({
          title: 'Snapshot taken. Already in Snapshot mode.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: 'Snapshot failed',
        description: 'Check the console for more information.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };
  

  const handleToggle = async (forceSnapshot = null) => {
    // Optionally force toggle to a specific mode (true for snapshot, false for live)
    const toggleMode = forceSnapshot !== null ? forceSnapshot : !isSnapshot;

    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/toggleLeaderboardMode`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ password, mode: toggleMode }), // Send the desired mode if forcing
    });

    const data = await response.json();

    if (response.ok) {
      setIsSnapshot(data.message.includes("snapshot: true"));
      toast({
        title: `Leaderboard mode toggled to ${toggleMode ? 'Snapshot' : 'Live'}.`,
        description: data.message,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } else {
      toast({
        title: 'Toggle failed',
        description: 'Check the console for more information.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <VStack spacing={4}>
      <Text fontSize="2xl">Admin Controls</Text>
      <Input
        placeholder="Enter admin password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Box d="flex" gap="20px">
        <Button colorScheme="blue" onClick={handleSnapshotAndToggle}>Save Snapshot & Toggle to Snapshot Mode</Button>
        <Button colorScheme="green" onClick={() => handleToggle(false)}>Set to Live Mode</Button>
      </Box>
      <Box mt="4">
        <Text>Current Mode: {isSnapshot ? "SNAPSHOT" : "LIVE"}</Text>
      </Box>
    </VStack>
  );
};

export default AdminPage;
